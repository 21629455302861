import React, { useRef, useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, H2, Reviews } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

import css from './ListingPage.module.css';

function createReview(uuid, name, rating, content, date) {
  return {
    id: {
      _sdkType: 'UUID',
      uuid: new UUID(uuid),
    },
    type: 'review',
    attributes: {
      type: 'ofProvider',
      state: 'public',
      deleted: false,
      createdAt: date,
      rating: rating,
      content: content,
    },
    author: {
      attributes: {
        profile: {
          abbreviatedName: name.split(' ')[0][0] + name.split(' ')[1][0],
          displayName: name,
          bio: null,
          publicData: {},
          metadata: {},
        },
        banned: false,
        deleted: false,
        createdAt: date,
      },
      id: {
        _sdkType: 'UUID',
        uuid: uuid,
      },
      type: 'user',
      profileImage: null,
    },
  };
}
const SectionReviews = props => {
  const { reviews, fetchReviewsError, listingId } = props;
  const buttonRef = useRef(null);

  const customReviews1 = [
    createReview(
      '662c0681-e361-4812-b38e-d23c06b97e99',
      'Lilit C',
      3,
      "I ordered Rosalia's Baptism cookies for my niece's christening in April 2024, and they were absolutely perfect! The designs were beautifully detailed, and the cookies tasted delicious. We chose a mix of cross-shaped cookies and ones with personalized messages, and everyone at the party loved them. The packaging was also elegant, making them a great gift option. I highly recommend Rosalia's for any special occasion!",
      '2024-04-15T10:30:00.000Z'
    ),
    createReview(
      '662ef0ee-414e-4c93-9071-89c41170ae2e',
      'Pan U',
      5,
      "We had Rosalia make cookies for our son's baptism. The cookies looked amazing and were very professionally done. We chose a classic design with white icing and silver accents, which fit the theme perfectly. The quality of the cookies made everything so worth it.",
      '2024-05-20T14:45:00.000Z'
    ),
  ];

  const customReviews2 = [
    createReview(
      '66218d40-198a-4c4a-8355-09e3ec856c2b',
      'Molly P',
      5,
      "For my sister's baby shower in June, we ordered custom cookies from Rosalia, and they were absolutely adorable! The theme was \"little prince,\" and Rosalia's team nailed it with cute blue and gold cookies shaped like crowns, baby onesies, and stars. The guests couldn't stop complimenting them. Shipping arrived right on time before my delivery date I selected.",
      '2024-06-15T10:30:00.000Z'
    ),
    createReview(
      '661ff938-3cca-4adc-8f46-09ddeb600abc',
      'Jessica T',
      3,
      'I ordered custom cookies from Rosalia for my wedding, and they were stunning! We chose a romantic theme with heart-shaped cookies, some decorated with our initials and others with delicate floral patterns. The cookies were not only a visual treat but also delicious, with just the right amount of sweetness. They were a perfect addition to our dessert table and made lovely favors for our guests. Rosalia was fantastic to work with, very accommodating to our specific design requests, and the cookies were shipped right on time. Highly recommended for any special occasion!',
      '2024-05-20T14:45:00.000Z'
    ),
  ];

  const uuidsToRemove = [
    '673cde45-ccb3-49fa-823b-c6a358775c29',
    '673cf9e2-df88-437a-894d-088da91a777c',
    '66f09382-9b55-4dc8-9e46-53f1a36667d9',
    '67339317-e167-46a0-8195-1485932cb508',
  ];

  // Filter reviews to exclude unwanted UUIDs
  const filteredReviews = reviews.filter(review => !uuidsToRemove.includes(review.id.uuid));

  // Get the custom reviews based on the listingId
  const customReviews =
    listingId.uuid === '66870f06-3019-403b-8957-ed985f4b1fee'
      ? customReviews1
      : listingId.uuid === '669553e4-5747-4530-a58e-4bdb8955950e'
      ? customReviews2
      : [];

  // Combine and remove duplicates
  const updatedReviews = [
    ...filteredReviews,
    // ...customReviews.filter(
    //   customReview =>
    //     !filteredReviews.some(review => review.id.uuid.value === customReview.id.uuid.value)
    // ),
  ];

  const [selectedCategory, setSelectedCategory] = useState('mostRecent');
  const [isExpanded, setIsExpanded] = useState(false);

  // Sort reviews based on selected category
  const sortedReviews = [...updatedReviews].sort((a, b) => {
    if (selectedCategory === 'highestRated') {
      return b.attributes.rating - a.attributes.rating;
    } else if (selectedCategory === 'lowestRated') {
      return a.attributes.rating - b.attributes.rating;
    } else if (selectedCategory === 'mostRecent') {
      return new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt);
    }
    return 0;
  });

  // Reviews to show (limit to 3 when collapsed)
  const reviewsToShow = isExpanded ? sortedReviews : sortedReviews.slice(0, 3);

  return (
    <div className={css.sectionReviews}>
      <div id="cometotop"></div>
      {fetchReviewsError ? (
        <H2 className={css.errorText}>
          <FormattedMessage id="ListingPage.reviewsError" />
        </H2>
      ) : null}

      {/* Dropdown for sorting */}
      {isExpanded && (
        <div className={css.dropdownContainer}>
          <label htmlFor="reviewDropdown" className={css.dropdownLabel}>
            <FormattedMessage id="ListingPage.sortReviews" defaultMessage="Sort by:" />
          </label>
          <select
            id="reviewDropdown"
            className={css.dropdown}
            value={selectedCategory}
            onChange={e => setSelectedCategory(e.target.value)}
          >
            <option value="mostRecent">Most Recent</option>
            <option value="highestRated">Highest Rated</option>
            <option value="lowestRated">Lowest Rated</option>
          </select>
        </div>
      )}

      {/* Display the reviews */}
      <Reviews reviews={reviewsToShow} />

      {/* Show More/Show Less Button */}
      {updatedReviews.length > 3 && (
        <button
          className={css.showMoreButton}
          onClick={() => {
            if (isExpanded) {
              const target = document.getElementById('cometotop');
              const yOffset = target.getBoundingClientRect().top + window.scrollY;
              window.scrollTo({ top: yOffset, behavior: 'smooth' });
            }
            setIsExpanded(!isExpanded);
          }}
        >
          {isExpanded ? (
            <>
              <FormattedMessage id="ListingPage.hideReviews" defaultMessage="Hide Reviews" />{' '}
              <span className={css.hidereviews}> ▲</span>
            </>
          ) : (
            <>
              <FormattedMessage
                id="ListingPage.showReviews"
                defaultMessage={`More Reviews (${sortedReviews.length})`}
              />
              <span className={css.showreviews}> ▼</span>
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default SectionReviews;
