import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const FETCH_STRIPE_PAYOUTS_REQUEST = 'payouts/FETCH_STRIPE_PAYOUTS_REQUEST';
export const FETCH_STRIPE_PAYOUTS_SUCCESS = 'payouts/FETCH_STRIPE_PAYOUTS_SUCCESS';
export const FETCH_STRIPE_PAYOUTS_FAILURE = 'payouts/FETCH_STRIPE_PAYOUTS_FAILURE';

// ================ Reducer ================ //

const initialState = {
  payouts: [],
  loading: false,
  error: null,
};

export default function payoutsReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_STRIPE_PAYOUTS_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_STRIPE_PAYOUTS_SUCCESS:
      return {
        ...state,
        loading: false,
        payouts: payload,
      };
    case FETCH_STRIPE_PAYOUTS_FAILURE:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchStripePayoutsRequest = () => ({
  type: FETCH_STRIPE_PAYOUTS_REQUEST,
});

const fetchStripePayoutsSuccess = payouts => ({
  type: FETCH_STRIPE_PAYOUTS_SUCCESS,
  payload: payouts,
});

const fetchStripePayoutsFailure = error => ({
  type: FETCH_STRIPE_PAYOUTS_FAILURE,
  payload: error,
});

// ================ Thunks ================ //

export const loadData = () => async (dispatch, getState, sdk) => {
  dispatch(fetchStripePayoutsRequest());

  try {
    const stripeAccountResponse = await sdk.stripeAccount.fetch();

    if (!stripeAccountResponse.data?.data?.attributes?.stripeAccountId) {
      throw new Error('The logged-in user does not have a Stripe Connect account.');
    }

    const stripeAccountId = stripeAccountResponse.data.data.attributes.stripeAccountId;

    const response = await fetch('/api/stripe-payouts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ stripeAccountId }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch payouts from the backend API.');
    }

    const { payouts } = await response.json();

    dispatch(fetchStripePayoutsSuccess(payouts));
  } catch (error) {
    dispatch(fetchStripePayoutsFailure(error.message));
  }
};
